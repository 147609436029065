import React from "react";
import "./intro.scss";
import { graphql, useStaticQuery } from "gatsby";

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      productsImg: file(relativePath: { eq: "product-page-intro-img.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="products-container container">
      <div className="products-left"
           data-sal="slide-right"

           data-sal-easing="ease-in-cubic"
           data-sal-duration="1000"
      >
        <div className="products-left-title">Products</div>
        <div className="products-left-text">
          <p>We love building applications! Sometimes we take a break from client work to focus on our own ideas. Our goal is to fill in the gaps that we often encounter during development or consulting work.
            Feel free to check them out, they might just make your life easier as they have for us and our clients. </p>
        </div>
      </div>
      <div className="products-right">
        <img alt="" src={data.productsImg.publicURL}/>
      </div>
    </div>
  )
};

export default Intro
