import React from "react";
import Layout from "../components/shared/layout/layout";
import Seo from "../components/seo";
import Intro from "../components/product/intro/intro";
import CurrentStatus from "../components/product/current-status/current-status";
import EstimatorFirst from "../components/product/estimator-first/estimator-first";
import EstimatorSecond from "../components/product/estimator-second/estimator-second";

const ProductPage = () => (
  <Layout>
    <Seo title="Products" />
    <Intro/>
    <CurrentStatus/>
    <EstimatorFirst/>
    <EstimatorSecond/>
  </Layout>
)

export default ProductPage
