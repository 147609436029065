import React from "react";
import "./estimator-second.scss";
import SuperQImage from "../../../images/superQ_screen.png";
import { graphql, useStaticQuery, Link } from "gatsby";

const EstimatorSecond = () => {
  const data = useStaticQuery(graphql`
    query {
      estimatorFirstImgBg: file(relativePath: { eq: "estimator-second-1.png" }) {
        publicURL
      },
      estimatorSecondImgBg: file(relativePath: { eq: "estimator-second-2.png" }) {
        publicURL
      },
      estimatorThirdImgBg: file(relativePath: { eq: "estimator-second-3.png" }) {
        publicURL
      },
    }
  `)

    return (
      <div className="estimator-second">
        <img alt="" className="estimator-second-content-img" src={SuperQImage}/>
        <img alt="" className="estimator-second-content-img" src={data.estimatorSecondImgBg.publicURL}/>
        <img alt="" className="estimator-second-content-img" src={data.estimatorThirdImgBg.publicURL}/>
        <div className="estimator-second-content"
             data-sal="slide-down"

             data-sal-easing="ease-in-cubic"
             data-sal-duration="1000"
        >
          <h3 className="estimator-second-content-title">SuperQ Admin</h3>
          <p className="estimator-second-content-text">One thing most admins agree on is that Salesforce CPQ out-of-the-box configuration interface is basic at best. It takes hours of tedious work to produce product and price rules, setup discount schedules and product bundles. 
          Once they are built the problems don't stop. There's also the challenge of moving configurations across different environments. Having struggled with this on multiple implementations ourselves we decided to build SuperQ. This tool will save you countless headaches and get your projects moving. 
          </p>
          <Link to="/#contact" className="yellow-btn">Learn More</Link>
        </div>
      </div>
    )
};

export default EstimatorSecond
