import React from "react";
import "./estimator-first.scss";
import PharosImage from "../../../images/pharos_ai_showcase.png";
import { graphql, useStaticQuery, Link } from "gatsby";

const EstimatorFirst = () => {
  const data = useStaticQuery(graphql`
    query {
      estimatorFirstImgBg: file(relativePath: { eq: "estimator-first-1.png" }) {
        publicURL
      },
      estimatorSecondImgBg: file(relativePath: { eq: "estimator-first-2.png" }) {
        publicURL
      },
      estimatorThirdImgBg: file(relativePath: { eq: "estimator-first-3.png" }) {
        publicURL
      },
    }
  `)

  return (
    <div className="estimator-first">
      <img alt="" className="estimator-first-content-img" src={PharosImage}/>
      <img alt="" className="estimator-first-content-img" src={data.estimatorSecondImgBg.publicURL}/>
      <img alt="" className="estimator-first-content-img" src={data.estimatorThirdImgBg.publicURL}/>
      <div className="estimator-first-content"
            data-sal="slide-down"

            data-sal-easing="ease-in-cubic"
            data-sal-duration="1000"
      >
        <h3 className="estimator-first-content-title">Pharos.ai</h3>
        <p className="estimator-first-content-text">Paladin Solutions is proud to present the only native error tracking and monitoring solution for Salesforce.
        This product seamlessly tracks all errors in your org, allows you to set up monitoring for critical areas of functionality and helps troubleshoot apex, process builder and flow issues.
        There is also a built-in Jira integration that will put issues to your dev team's radar and help you track completion status. 
        </p>
        <Link to="https://pharos.ai" className="yellow-btn">Learn More</Link>
      </div>
    </div>
  )
};

export default EstimatorFirst
